import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentServicingShared } from '../../../../../../libs/env/src/index';
import { env as baseEnv } from '../env';

export const env: EnvironmentServicingShared = merge({}, baseEnv, {
  static: {
    partnerId: '534',
    title: 'CONNECT Insurance',
  },
  chatEnabled: true,
  genesysChatDeploymentId: 'f45fba92-42db-4cb3-8b9d-d9c5533e1e82',
  genesysChatQueueName: 'HC_CONNECT_Partners',
  genesysChatEnvironment: 'prod',
  hotjarEnabled: true,
});
